import type { LoaderFunctionArgs, MetaFunction } from '@remix-run/node'
import { json } from '@remix-run/node'
import { useTranslation } from 'react-i18next'
import { GeneralErrorBoundary } from '~/components/error-boundary.tsx'
import {
  type BlogDynamicBodyFragment,
  type DynamicPageBodyFragment,
  type HomepageBodyFragment,
  PublicationState,
} from '~/model/api.ts'
import { api } from '~/utils/gql-client.server.ts'
import { getPageState } from '~/utils/page-state.ts'
import { fallbackLng } from '~/utils/i18n.ts'
import { invariantResponse, useIsHome } from '~/utils/misc.ts'
import { useLoaderData, useLocation } from '@remix-run/react'
import { DynamicZone } from '~/components/dynamic-zone/dynamic-zone.tsx'
import { transformBody } from '~/utils/dynamic-zone.server.ts'
import type { MetaProps } from '~/utils/meta.ts'
import { generateMeta, validateSeo } from '~/utils/meta.ts'
import { markdown } from '~/utils/markdown.server.ts'
import { Markdown } from '~/components/markdown.tsx'
import { Gradient } from '~/components/gradients.tsx'
import { Cols } from '~/components/cols.tsx'
import { Article } from '~/components/article.tsx'
import { getGeoLocation } from '~/utils/geo.server.ts'
import {
  createAnonymousContext,
  getFlagValue,
  getVisitorIdCookieData,
  parseExperimentVariant,
} from '~/utils/launchdarkly.server.ts'
import { getHomepageData } from '~/utils/homepage.server.ts'
import { Layout } from '~/components/layout.tsx'
import { StructuredData } from '~/components/structured-data.tsx'
import { trackContentLayers } from '~/utils/gtm.client.ts'
import { useEffect } from 'react'

const homepageStructuredData: StructuredData = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Rouvy',
  url: 'https://rouvy.com',
  logo: 'https://rouvy.com/path/to/logo.png',
  contactPoint: [
    {
      '@type': 'ContactPoint',
      telephone: '+420 910 920 986',
      contactType: 'customer service',
      areaServed: 'Global',
      availableLanguage: ['English'],
    },
  ],
  email: 'support@rouvy.com',
  sameAs: [
    'https://www.facebook.com/rouvy',
    'https://www.instagram.com/rouvy',
    'https://www.twitter.com/rouvy',
    'https://www.linkedin.com/company/rouvy',
  ],
  address: [
    {
      '@type': 'PostalAddress',
      streetAddress: 'Rustonka 2, Rohanské nábř. 693/10',
      addressLocality: 'Praha 8-Karlín',
      postalCode: '186 00',
      addressCountry: 'CZ',
    },
  ],
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return data
    ? generateMeta(data?.metaProps as MetaProps)
    : [
        {
          title: 'Not found',
        },
      ]
}

export async function loader({ request }: LoaderFunctionArgs) {
  const pageState = await getPageState(request)

  let staticPage
  let body
  let navbarSettings
  let metaProps: MetaProps
  let experiments = []
  let contentLayers

  const visitorIdCookieData = await getVisitorIdCookieData(request)

  if (pageState.path.length === 0) {
    let variant = pageState.variant

    if (pageState.publicationState !== PublicationState.Preview || !variant) {
      const countryCode = await getGeoLocation(request)
      const context = createAnonymousContext(visitorIdCookieData.visitorId)
      variant = await getFlagValue(
        'web-homepage-variant',
        { ...context, countryCode },
        'default',
      )
    }

    const homepage = await getHomepageData({
      variant: parseExperimentVariant(variant),
      locale: pageState.locale ? pageState.locale : fallbackLng,
      publicationState: pageState.publicationState,
    })

    invariantResponse(homepage.homepage?.body, 'Page not found', {
      status: 404,
    })

    body = homepage.homepage.body.filter(component => component !== null)
    metaProps = {
      seo: await validateSeo(homepage.homepage.SEO, request),
      pageState: pageState,
      localizations: homepage.homepage.localizations?.data.map(
        a => a.attributes?.locale,
      ) as string[],
    }
    experiments.push({
      experiment: 'web-homepage-variant',
      variant: variant,
    })
    navbarSettings = homepage.homepage.navbarSettings
  } else {
    const staticPageResponse = await api.StaticPage({
      locale: pageState.locale,
      slug: pageState.path,
      publicationState: pageState.publicationState,
    })

    if (staticPageResponse.staticPage) {
      metaProps = {
        seo: await validateSeo(staticPageResponse.staticPage.SEO, request),
        pageState: pageState,
        localizations: staticPageResponse.staticPage.localizations?.data.map(
          a => a.attributes?.locale,
        ) as string[],
      }
      staticPage = {
        ...staticPageResponse.staticPage,
        transformedText: markdown(staticPageResponse.staticPage.text ?? ''),
      }
    } else {
      const { dynamicPageByPath } = await api.DynamicPage({
        path: `/${pageState.path}`,
        locale: pageState.locale,
        publicationState: pageState.publicationState,
      })
      invariantResponse(dynamicPageByPath, 'Page not found', { status: 404 })

      body = dynamicPageByPath.body?.filter(component => component !== null)
      metaProps = {
        seo: await validateSeo(dynamicPageByPath.SEO, request),
        pageState,
        localizations: dynamicPageByPath.localizations?.data.map(
          a => a.attributes?.locale,
        ) as string[],
      }
      navbarSettings = dynamicPageByPath.navbarSettings
      contentLayers = dynamicPageByPath.contentLayers
    }
  }

  return json(
    {
      staticPage: staticPage,
      body: body
        ? await transformBody(
            body as
              | HomepageBodyFragment[]
              | DynamicPageBodyFragment[]
              | BlogDynamicBodyFragment[],
            request,
          )
        : null,
      contentLayers,
      navbarSettings,
      metaProps,
      experiments,
    },
    visitorIdCookieData.serializedCookie
      ? {
          headers: { 'Set-Cookie': visitorIdCookieData.serializedCookie },
        }
      : undefined,
  )
}

export default function Index() {
  const { body, staticPage, navbarSettings, contentLayers } =
    useLoaderData<typeof loader>()
  const isHome = useIsHome()
  const { pathname } = useLocation()

  useEffect(() => {
    if (contentLayers) {
      trackContentLayers(contentLayers, pathname)
    }
  }, [contentLayers, pathname])

  if (staticPage) {
    return (
      <Layout>
        <div className="relative overflow-hidden py-32">
          <Gradient />

          <div className="container relative z-10">
            <Cols size="10">
              <div className="flex flex-col gap-6">
                <h1 className="font-display text-display-60 font-extrabold uppercase italic leading-tight text-text-default">
                  {staticPage.title}
                </h1>
              </div>
            </Cols>
          </div>
        </div>

        <div className="container">
          <Cols size="10" className="py-24">
            <Article>
              <Markdown content={staticPage.transformedText} />
            </Article>
          </Cols>
        </div>
      </Layout>
    )
  }

  // @ts-ignore
  return (
    <Layout navbarSettings={navbarSettings ?? undefined}>
      <DynamicZone unionMembers={body as any} />
      {isHome && <StructuredData structuredData={homepageStructuredData} />}
    </Layout>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation()
  return (
    <GeneralErrorBoundary
      statusHandlers={{
        404: () => (
          <p className="text-body-20 font-semibold text-text-muted">
            {t('page_not_found')}
          </p>
        ),
      }}
    />
  )
}
